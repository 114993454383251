import './members.css';
import { useEffect, useState} from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';


function Members() {

  const [queue, setQueue] = useState([]);
  const [lastrefresh, setLastRefresh] = useState('Refreshing every 30 seconds');
  const [passcode, setPasscode] = useState('');

  const JsonTable = ({ data }) => {
    return (
      <table>
        <thead>
          <tr>
            <th>Arrived</th>
            <th>Firstname</th>
            <th>Lastname</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          {data.map((a, index) => (
            <tr key={index}>
              <td>{a.arrived}</td>
              <td>{a.firstname}</td>
              <td>{a.lastname}</td>
              <td>{a.email}</td>
              <td>{a.phone}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  const handleInputChange = (event) => {
    setPasscode(event.target.value);
  };

  const fetchData = async () => {
    try {
  // Your code to be executed when currentEvent is empty
  const apiURL = `${process.env.REACT_APP_RESTAPIURL}/getqueue/${passcode}`;
        await axios.get(apiURL).then((response)=>{
        const data=response.data;
        //console.log(JSON.stringify(data));   
        setQueue(data);
        setLastRefresh(new Date().toLocaleTimeString('en-US'));     
        }).catch((error)=>{

            if (error.response.status===404)
            {
                console.log("404 not found!!");
            }
            console.log(error.response);
        })
    } catch (error) {
        // Handle errors
        console.error('Error fetching data:', error);
      }
    };

  useEffect(() => {
    // Check if currentEvent is empty before executing the code
    const intervalId = setInterval(fetchData, 30000); // 30 seconds in milliseconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
       // fetchData();
    
  }, []); 

  return (
    <div style={{backgroundColor:'white'}}>
      <h1>QUEUE</h1>
      <p>Passcode:  <input type="password" value={passcode} onChange={handleInputChange} placeholder="Type something..."
      /></p>
      <p>Last refresh (automatically every 30s) - {lastrefresh} - <Button onClick={fetchData}>Force Refresh</Button> </p>
<JsonTable data={queue}></JsonTable>
    </div>
  
  );
}

export default Members;