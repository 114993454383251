import './viewphotos.css';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import { Button } from 'react-bootstrap';


function ViewPhotos({ children }) {

    const {state} = useLocation();
    const { firstname,lastname,email,phone, photos } = state; 
    const [currentImage, setCurrentImage] = useState(0);



    function formatDate(inputDateString) {
        // Parse the input string (assumes MMDDYYYY format)
        const month = parseInt(inputDateString.substring(0, 2), 10) - 1; // Month is 0-indexed
        const day = parseInt(inputDateString.substring(2, 4), 10);
        const year = parseInt(inputDateString.substring(4), 10);

      
        // Create a Date object
        const date = new Date(year, month, day);
      
        // Define options for formatting
        const options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };
      
        // Format the date
        const formattedDate = date.toLocaleDateString('en-US', options);
      
        return formattedDate;
      }
      
      // Example usage
   //   const inputDateString = '11212023'; // Assuming MMDDYYYY format
     // const formattedDate = formatDate(inputDateString);
     // console.log(formattedDate);
      // <Carousel.Item>{f}<img width='200px' height='200px' src={'/photos/'+f} alt={i} /></Carousel.Item>
/*{a.files.map((f,i)=>(
                <ImageGallery items={f}></ImageGallery>
                    {a.files.map((file, fileIndex) => ( `${file}` ))}
        ))}*/
    function handleReturn()
    {
       window.location.assign('/');
    }
   
  return (
    <div>
       
       <div><Button style={{padding:'5px',margin:'10px'}} onClick={handleReturn}>&larr;&nbsp;RETURN TO HOME PAGE</Button></div>

    <ul>{state.photos===undefined?<div><br/><br/>No images available.</div>:<div>
            {state.photos.map((a, index) => (
              <div style={{border:'1px solid black',padding:'20px',marginBottom:'50px',backgroundColor:'lightgray'}}>
                
                        <li key={index}>
                        <b>{formatDate(a.eventdate)}&nbsp;&nbsp;-&nbsp;&nbsp;{a.event}</b> <div style={{padding:'5px'}}><a className='downloadbutton' href={'./photos/'+state.phone+'/'+a.eventdate+'/'+a.eventdate+'-all.zip'} download={a.eventdate+'-all.zip'} target="_blank" rel="noreferrer">Download all pictures (.zip)</a></div>
                        </li><br/>
                    <div className='image-gallery-wrapper'>
                        <ImageGallery showPlayButton={false} onErrorImageURL={'./photos/noimageavailable.jpeg'} onSlide={(index) => setCurrentImage(index)} items={a.files.map((file, fileIndex) => ({
                    original: `photos/${state.phone}/${a.eventdate}/${file}`, thumbnail: `photos/${state.phone}/${a.eventdate}/T_${file}`
                    }))} />  </div>
             
                        <div style={{padding:'30px'}}><a className='downloadbutton' href={'./photos/'+state.phone+'/'+a.eventdate+'/'+a.files[currentImage]} download={a.eventdate+'-'+a.files[currentImage]} target="_blank" rel="noreferrer">Download this Picture</a></div>
                        
              </div>
            ))}</div>}
          </ul>
    </div>
  
  );
}

export default ViewPhotos;