import './register.css';
import Section from './section';
import { Form,Button, Col,Image } from 'react-bootstrap';
import { useState } from 'react';
import axios from 'axios';
//import { useNavigate } from 'react-router-dom';


const uri=process.env.REACT_APP_RESTAPIURL; //"http://localhost:3500"


function Register() {
    const [validated, setValidated] = useState(false);
    const [showsuccess, setshowsuccess] = useState(false);
    const [showspinning, setshowspinning]=useState(false);
   // const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstname: '',
        lastname:'',
        email: '',
        phone: ''
      });

      const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
      };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(false);

    }
    else{
        //Send it
      //  console.log(JSON.stringify(formData));
        setshowspinning(true);
        try {
            // Make a POST request to the /register endpoint
            const response = await axios.post(uri+'/register', formData);
            console.log(JSON.stringify(response));
            if (response.data.matchedCount===1 || response.data.upsertedCount===1)
            {
                setshowsuccess(true);
              //  console.log('Registration successful:', response.data);
            }
       
    
          } catch (error) {
            // Handle errors (e.g., display an error message)
            console.error('Error registering:', error.response.data);
    
          }
          setshowspinning(false);
    }        
    setValidated(true);
   
    
  };
  const handleReload = () => {
   // navigate('/register');
    window.location.reload();
  };
  return (
    <div>
    
      <Section>
      <h1>REGISTER</h1>
      {!showsuccess?<div><p style={{padding:'10px'}}>Your provided information is solely for communication between Holden Local 4557 and you. We do not share or sell any of this information to third parties.</p>
        <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete='off'>
            <Form.Group as={Col} controlId="formGridName">
                <Form.Label>First Name*</Form.Label>
                <Form.Control required name="firstname" type="text" placeholder="First Name" value={formData.firstname} onChange={handleInputChange}  />
                <Form.Label>Last Name*</Form.Label>
                <Form.Control required name="lastname" type="text" placeholder="Last Name" value={formData.lastname} onChange={handleInputChange} />
                <Form.Control.Feedback type="invalid">Please provide a name.</Form.Control.Feedback>
     
            </Form.Group>

            <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email*</Form.Label>
                <Form.Control required name="email" type="email" placeholder="Enter email" value={formData.email} onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">Please provide an email address.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridMobile">
                <Form.Label>Contact Phone Number</Form.Label>
                <Form.Control required name="phone" type="text" placeholder="10-digit phone" value={formData.phone} onChange={handleInputChange} />
                <Form.Control.Feedback type="invalid">Please provide a valid cell phone just the numbers i.e. 9782341234</Form.Control.Feedback>

            </Form.Group>
<br/>
            {(showspinning?<div><h1>Saving......&nbsp;&nbsp;&nbsp;<Image src='saving.gif' height={'80px'} width={'80px'}/></h1></div>:<Button style={{ width:'100%', padding:'20px'}} variant="primary" type="submit"><h3>Submit</h3></Button>)}
        </Form >
        </div>:<div><br/><br/><h2 style={{textAlign:'center'}}>Registraion Successful!</h2><br/><br/><Button style={{ width:'100%', padding:'20px'}} variant='success' onClick={handleReload}><h3>Finish</h3></Button></div>}
      </Section>
    </div>
  
  );
}

export default Register;