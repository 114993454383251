
import './photos.css';
import {React,useState} from 'react';
import axios  from 'axios';
import { useNavigate } from  'react-router-dom'

const uri=process.env.REACT_APP_RESTAPIURL; //"http://localhost:3500"

function Photos({ children }) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const navigate = useNavigate();
    const [showbadNumber, setBadNumber] = useState(false);

   // const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  //  const isValidPhoneNumber = /^\d{10}$/.test(phoneNumber);
    // useEffect(() => {
    //     setPhoneNumber(phoneNumber.replace(/[^+\d]+/g, ""));
    //   }, [phoneNumber]);

 //   const phoneRegex = /^\d{10}$/;
/*
   

    const handleLinkClick = () => {
       
        if (isValidPhoneNumber) {
            setPhoneNumber(''); // Clear the phone number when the link is clicked
            setTextboxVisibility(!isTextboxVisible); // Toggle textbox visibility
            alert('redirecting to show photos');
            return;
          } else {
            alert('Invalid phone number format. Please enter a 10-digit number.');
          }
      };
      const handleTextboxChange = (event) => {
        event.preventDefault();
        setPhoneNumber(event.target.value);
      };
  //    setIsValidPhoneNumber(phoneRegex.test(phoneNumber));
   {isTextboxVisible && (
  */
//   const handleLinkClick = () => {
   
//     setPhoneNumber(''); // Clear the phone number when the link is clicked
//     setTextboxVisibility(!isTextboxVisible); // Toggle textbox visibility

//   };
  const handleTextboxChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleViewPhotosClick = async (e) => {
    //Prune
    e.preventDefault();
    try{
        
    if (phoneNumber.length<10)
    {
        setBadNumber(true);
        return;
    }
   // setPhoneNumber(phoneNumber.replace(/[^+\d]+/g, ""));
    const prunedPhoneNumber = phoneNumber.replace(/[^+\d]+/g, "");
   // console.log("Pruned Phone Number:", prunedPhoneNumber);
    setPhoneNumber(prunedPhoneNumber);

    // Construct the API URL
    const apiURL = `${uri}/getphoto/${prunedPhoneNumber}/`;
  //  console.log("API URL:", apiURL);
    
    //Call the RESTAPI
    /*_id
FN
LN
CELL
EMAIL
photos:[
{event_name: ‘Pictures with Santa 2023’, event_date}    folder=phonenumber/date */

    await axios.get(apiURL).then((response)=>{
        const data=response.data;
     //   console.log(JSON.stringify(data));
        setBadNumber(false);
        navigate('viewphotos',{ state:data});
    }).catch((error)=>{
        if (error.response.status===404)
        {
            setBadNumber(true);
        }
        console.log(error.response);
    })
    }
    catch (e) {
        console.log('error='+e);
    }
    /*
    if (isValidPhoneNumber) {
      // Your logic to redirect or perform some action when the phone number is valid
      alert('Redirecting to show photos');
    } else {
      alert('Invalid phone number format. Please enter a 10-digit number.');
    }*/
  };

  /* if (isValidPhoneNumber) {
        setPhoneNumber(''); // Clear the phone number when the link is clicked
        setTextboxVisibility(!isTextboxVisible); // Toggle textbox visibility
        alert('redirecting to show photos');
        return;
      } else {
        alert('Invalid phone number format. Please enter a 10-digit number.');
      }*/
  return (
    <div className='photos'>
     
        <div><span>Enter the phone number used when you registered for the photos.</span><br/><br/>
          <input
            size='15'
            type="text"
            placeholder="phone number"
            value={phoneNumber}
            onChange={handleTextboxChange}
          /><br/><br/>
          <button style={{padding: '10px'}} onClick={handleViewPhotosClick}>
            View Photos
          </button>
          <div style={{visibility:(showbadNumber?'visible':'hidden'),paddingTop:'10px'}}><p style={{ color:'red'}}>This number is not associated with any photos.<br/>Enter only numbers no ( ) - symbols.</p></div>
        </div>
      
    </div>

  
  );
}

export default Photos;

