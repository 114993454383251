import './home.css';
import { useEffect,useState} from 'react';

import Section from './section';
import Photos from './photos'
import axios from 'axios';

const options = {
    timeZone: 'America/New_York',
    weekday: 'long', // Full day of the week (e.g., "Monday")
    year: 'numeric', // Full year (e.g., "2023")
    month: 'long', // Full month name (e.g., "January")
    day: 'numeric', // Day of the month (e.g., "1")
    hour: 'numeric', // Hour (e.g., "12")
    minute: 'numeric', // Minute (e.g., "30")
    hour12: true, // Use 12-hour clock (true) or 24-hour clock (false)
  }
function Home() {
    const [currentEvent, setCurrentEvents] = useState();

    useEffect(() => {
        // Check if currentEvent is empty before executing the code
        const fetchData = async () => {
            try {
          // Your code to be executed when currentEvent is empty
          console.log('load current event...'+process.env.REACT_APP_RESTAPIURL);
          const apiURL = `${process.env.REACT_APP_RESTAPIURL}/getevents/`;
                await axios.get(apiURL).then((response)=>{
                const data=response.data;
                console.log(JSON.stringify(data));   
                setCurrentEvents(data);     
                }).catch((error)=>{

                    if (error.response.status===404)
                    {
                        console.log("404 not found!!");
                    }
                    console.log(error.response);
                })
            } catch (error) {
                // Handle errors
                console.error('Error fetching data:', error);
              }
            };
            fetchData();
        
      }, []); 

    function formattime(f)
    {
        var d=new Date(f)
        return d.toLocaleString('en-US', options);
    }
    function areDatesEqual(date1, date2) {
        var startDate = new Date(date1);
        var endDate = new Date(date2);
      
        return (
          startDate.getDate() === endDate.getDate() &&
          startDate.getMonth() === endDate.getMonth() &&
          startDate.getFullYear() === endDate.getFullYear()
        );
      }
      function getTimeAsString(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm="AM";
        // Ensure two-digit format (e.g., 09 instead of 9)
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        if (hours>12) { ampm="PM"; hours=hours-12; }
      
        return `${hours}:${minutes} ${ampm}`;
      }
      
      
    function formatendtime(s,e)
    {
        var s2=new Date(s)
        var e2=new Date(e)

       if (areDatesEqual(s2,e2)===true)
       {
        return getTimeAsString(e2);
       }
       return s2.toLocaleString('en-US', options);
    }
    
  return (
<div>
   <Section>
   <h1>ABOUT</h1>
   <p style={{padding:'10px'}}>Welcome to the Holden Professional Firefighters Local 4557, where commitment to public service extends beyond emergency response. Our union, comprised of over 20 professional firefighters, not only ensures the safety and well-being of our community but also actively engages in public activities throughout the year.  As we stand united, both in times of crisis and in moments of joy, our fire department union embodies the true spirit of community partnership and service. Join us at one of our many events as we continue to forge connections and make a positive impact on the lives of those we are honored to protect and serve.</p>
   <a style={{padding:'10px'}} href="https://www.facebook.com/iaff4557/" target='_'><img src='Facebook_Logo.png' alt='Facebook logo' width='50px' height='50px'/></a>
   </Section>

   <Section>
   <h1>UPCOMING EVENTS</h1>
   {currentEvent?currentEvent.map((event,index)=>(
    <p key={index} style={{padding:'10px',border:'1px solid black',backgroundColor:'#efefef',color:'red',fontSize:'1.5em'}}><b>{formattime(event.eventdate_start)} to {formatendtime(event.eventdate_start,event.eventdate_end)}</b><br/><br/>{event.title}<br/>{event.location}<br/>{event.description}<br/></p>
   )):''}
   </Section>

   <Section>
   <h1>PHOTOS</h1>
   <p>Did you attend one of our fund raising events and would like to access your photos?</p>
   <Photos>View My Photos</Photos>
   </Section>
   </div>
  );
}

export default Home;